import React from 'react';

function Header() {
  return (
      <div className="header-container">
        <div className="header container mx-auto">
          <div className="logo">Twitch Goal Editor</div>
          <div className="madeBy"><p>Made by <a href="https://youtube.com/@broasteroven" target="_blank">BroasterOven</a></p> <a href="https://youtube.com/@broasteroven" target="_blank"><img src="https://static-cdn.jtvnw.net/jtv_user_pictures/d5adce5a-b569-44ef-b31b-3d79946bad78-profile_image-70x70.png"/></a></div>
        </div>
      </div>
  );
}

export default Header;