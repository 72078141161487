import React from 'react';
import {debounceQueryUpdate} from '../../hooks/useQueryParams';

function Range(props) {
  const {name, setFunction, value, max, min = 0, label} = props;

  const onChange = (event) => {
    setFunction(event.target.value);
    debounceQueryUpdate(event.target.value, name);
  }

    return (
        <div className={"range " + (props.className ? props.className : '')}>
            <label className="control-title" htmlFor={label.replace(/\s/g, "").toLowerCase()}>{label}</label>
            <input
            type="range"
            value={value}
            name={label.replace(/\s/g, "").toLowerCase()}
            className={`
                form-range
                appearance-none
            `}
            min={min} 
            max={max}
            id="customRange1"
            onChange={onChange}
            />
        </div>
    );
  }
  
  export default Range;