import React, {useState, useEffect, useRef, useMemo, useCallback} from 'react';
import TwitchGoal from './components/twitch-goal-v2';
import Editor from './components/editor';
import CodeBlock from './components/code-block';
import {fonts} from './constants/fonts';
import {getQueryValue} from './hooks/useQueryParams';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import { TwitchChangeNotice } from './components/twitch-change-notice';


function GoalEditor() {
  const [progressColor, setProgressColor] = useState({
    firstColor: '#' + getQueryValue("progressColor.firstColor", "f357ca"), 
    secondColor: '#' + getQueryValue("progressColor.secondColor", "fff"),
    useGradient: getQueryValue("progressColor.useGradient", false),
  });
  const [backgroundColor, setBackgroundColor] = useState({
                                          firstColor: '#' + getQueryValue("backgroundColor.firstColor", "fff"), 
                                          secondColor: '#' + getQueryValue("backgroundColor.secondColor", "fff"),
                                          useGradient: getQueryValue("backgroundColor.useGradient", false),
                                        });
  const [fontColor, setFontColor] = useState('#' + getQueryValue("fontColor", '53535f'));
  const [fontSize, setFontSize] = useState(getQueryValue("fontSize", '45'));
  const [stackText, setStackText] = useState(getQueryValue("stackText", false));
  const [borderRadius, setBorderRadius] = useState(getQueryValue("borderRadius", '10'));
  const [width, setWidth] = useState(getQueryValue("width", '800'));
  const [height, setHeight] = useState(getQueryValue("height", '100'));
  const [imgVisible, setImgVisible] = useState(getQueryValue("imgVisible", true));
  const [borderVisible, setBorderVisible] = useState(getQueryValue("borderVisible", true));
  const [borderColor, setBorderColor] = useState('#' + getQueryValue("borderColor", '000'));
  const [borderSize, setBorderSize] = useState(getQueryValue("borderSize", '3'));
  const [progressPosition, setProgressPosition] = useState(getQueryValue("progressPosition", 'behind'));
  const [progressBarHeight, setProgressBarHeight] = useState(getQueryValue("progressBarHeight", '10'));
  const [selectedFont, setSelectedFont] = useState(getQueryValue("selectedFont", fonts[0]));
  const [titleVisible, setTitleVisible] = useState(getQueryValue("titleVisible", fonts[0]));
  const [styles, setStyles] = useState('');
  const discordLink = "https://discord.gg/TGGtXqdh";

  const settings = {
    progressColor,
    fontColor,
    fontSize,
    borderRadius,
    imgVisible,
    borderVisible,
    borderColor,
    backgroundColor,
    width,
    height,
    borderSize,
    progressPosition,
    progressBarHeight,
    selectedFont,
    titleVisible,
    stackText,
    styles
  }

   useEffect(()=> {
    const deriveColor = (element) => {
      if(settings[element].useGradient){
        return 'linear-gradient(90deg, ' + settings[element].firstColor + ' 0%, ' + settings[element].secondColor + ' 100%)';
      }
      return settings[element].firstColor;
    }

    let style = `${(selectedFont?.link ? `@import url('`+ selectedFont?.link +`');\n` : '')}` +
    `#root .goal_widget__container {
          line-height: 1;
          height: 100%!important;
          width: 100%px!important;
          font-size: `+ fontSize + `px;`+
          `${selectedFont?.link ? `\n          font-family: ${selectedFont.family};` : ''}
        }\n` + 

        `.goal_widget__container .goal_widget__progress_bar{
            position: absolute !important;
            display: ${(progressPosition === 'off' ? "none" : "initial")}!important;
            top: `+ (progressPosition === 'bottom' ? 'initial' : '0px') +`!important;
            bottom: `+ (progressPosition === 'top' ? 'initial' : '0px') +`!important;
            height: `+ (progressPosition === 'top' || progressPosition === 'bottom' ? progressBarHeight + 'px' : '100%') +`!important;
            background: ${deriveColor('progressColor')}!important;
        }\n` + 
        
        `.goal_widget{
            position: relative !important;
            overflow: hidden !important;
            width: 100% !important;
            height: 100% !important;
            border-radius:` + borderRadius  + `vh!important;
            background: ${deriveColor('backgroundColor')}!important;
            ${borderVisible ? `border: ` + borderSize + `px solid `+ borderColor + ``: `border-width: 0px`}!important;\n` +
        `}\n` + 
        
        `#root .goal_widget__body{
            position: relative !important;
            display: flex !important;
            padding-left: ` + (imgVisible ? '2rem' : '0rem')  + `!important;
            -webkit-box-align: center !important;
            align-items: center !important;
            width: 100% !important;
            height: 100% !important;
        }\n` + 
        
        `#root .goal_widget__metadata{
            display: flex !important;
            padding-left: 20px !important;
            padding-right: 20px !important;
            flex-wrap: wrap !important;
            width: 100% !important;
            flex-direction: ${stackText ? 'column' : 'row' };
            justify-content: ${stackText ? 'center' : 'space-between' };
            line-height: 1;
            align-items: ${stackText ? 'flex-start' : 'center' };
        }\n` + 
        
        `#root .goal_widget__metadata > div{
          color: `+ fontColor +`!important;
          
        }\n` + 

        `.goal_widget__metadata div:first-child{
            -webkit-box-align: center !important;
            align-items: center !important;
            ` + (titleVisible ? `display:flex`: `display:none`) + `!important;
        }\n` + 
        
        `.goal_widget__contributions{
            display: flex !important;
        }\n` + 

        (!imgVisible ? `.goal_widget__image {
          display: none;
        }` : ``)

        setStyles(style);
  }, [progressColor, backgroundColor, height, width, imgVisible, borderSize, borderVisible, borderRadius, borderColor, fontSize, fontColor, progressPosition, progressBarHeight, selectedFont, titleVisible, stackText]);

  

  const update = {
    setProgressColor,
    setFontColor,
    setFontSize,
    setBorderRadius,
    setImgVisible,
    setBorderVisible,
    setBorderColor,
    setBackgroundColor,
    setWidth,
    setHeight,
    setBorderSize,
    setProgressPosition,
    setProgressBarHeight,
    setSelectedFont,
    setTitleVisible,
    setStackText,
    setStyles
  }

    return (
      <>
        {/* <BrowserView>
          <TwitchGoal settings={settings}/>
          <Editor update={update} settings={settings}/>
            <div className="bg-white p-10 directions container mx-auto">
              <h4 className="section-title">Instructions</h4>
              <p>Create a new browser Source in OBS and include your <a href="https://help.twitch.tv/s/article/creator-goals?language=en_US" target="_blank">twitch goal link</a>. Set the <span className="font-bold">Width: {width}</span> and <span className="font-bold">Height: {height}</span>. Then add the below CSS into the <span className="font-bold">Custom CSS box.</span></p> 
              <p><span className="font-bold">Note:</span> Height and Width in the editor doesn't effect the CSS below, it is just to help visualize in the example above. If there is any sizing issues with your widget content once in OBS, you can change the width and height in the browser source properties in OBS. Have any issues/suggestions? <a href={discordLink} target="_blank">Join my discord</a> to let me know! </p>
            </div>
          <CodeBlock styles={styles}/>
        </BrowserView> */}
        <TwitchChangeNotice discordLink={discordLink}/>
        {/* <MobileView>
          <div className="mobileNotice container mx-auto p-10 pb-10 bg-white mt-10">
            <h4 className="section-title">Mobile Users:</h4>
            <p>The Twitch Goal Editor only works on desktop devices. This decision was made because in order to use this tool properly, you will need to copy over CSS code into OBS. Please revisit the tool on the computer you are running OBS from.</p>
            <p>You can read about the tool below. If you are on a non-mobile device and feel like you are seeing this notice in error, please <a href={discordLink} target="_blank">join my discord</a> and post a message in the "bugs" channel under the MyTwitch.tv section.</p>
          </div>
        </MobileView> */}
        <div className="about container mx-auto py-6 md:py-12 grid grid-cols-1 md:grid-cols-2 gap-6 md:gap-12 py-5">
            <div className="bg-white p-10">
              
              <h4 className="section-title">About</h4>
              <p>Hello, my name is BroasterOven. I was tired of having to update numbers before every stream when my sub/follower widgets on other providers would get out of sync. But I never liked how the Twitch Goal Widget looked and the customization options are basically non-existant. </p>
              <p>As a front-end developer by day, I started doing a little research. I found that you can provide custom CSS to style browser sources directly in OBS Studio. I finally was able to style the widget to fit my branding! I then realized this is probably something many others would like to do, so I set out to build a tool that would enable other streamers the oportunity to do the same.</p>
              <p>This Twitch Goal Editor allows for customization of the built in Goal Widget from Twitch.tv. This tool works for both the Subscriber and Follower versions of the Goal Widget.</p>
            </div>
            <div className="bg-white p-10">  
              <h4 className="section-title">Why?</h4>
              <p>The Goal widget that twitch provides has benefits over other goal widgets since it is built into twitch. The widget has the ability to stay up-to-date with current sub/follower numbers, can be edited on the fly from your twitch back office and can be edited by you or your mods by using chat commands. </p>
              <p>The downside? It looks terrible. Its very difficult to use the widget on your stream and make it feel like its part of your brand.</p>
              <p>Enter this tool. You are able to edit the widget to closer resemble your branding and make your widget standout from the crowd.</p>
            </div>
          </div>
          <div className="support container mx-auto pb-10">
            <div className="bg-white p-10">
              <h4 className="section-title">Issues / Suggestions / Questions</h4>
              <p>Did you find a bug, have a suggestion or have an idea for a new tool you would like to see? <a href={discordLink} target="_blank">Join my discord</a> and refer to the "MyTwitch.tv" section! p.s. you can even show off your design in the "share-your-design" channel!</p>
            </div>
            </div>
      </>
    );
  }
  
  export default GoalEditor;