import React from 'react';

function Footer() {
  return (
    <div className="footer-container">
    <div className="footer container mx-auto">
      <div className="logo">Twitch Goal Editor</div>
      <div className="madeBy"><p>Wanna support me? <a href="https://youtube.com/@broasteroven" target="_blank">Subscribe to my youtube channel!</a></p> <a href="https://youtube.com/@broasteroven" target="_blank"><img src="https://static-cdn.jtvnw.net/jtv_user_pictures/d5adce5a-b569-44ef-b31b-3d79946bad78-profile_image-70x70.png"/></a></div>
    </div>
  </div>
  );
}

export default Footer;