import React from 'react';
import Border from "./editor-components/border";
import General from './editor-components/general';
import Font from './editor-components/font';
import ProgressBar from './editor-components/progressBar';

function Editor(props) {

    return (
      <div className="editor container mx-auto mb-10">
        <div className ="grid grid-cols-2 gap-4">
          <General {...props}/>
          <Font {...props}/>
          <ProgressBar {...props}/>  
          <Border {...props}/>
        </div>
      </div>
    );
  }
  
  export default Editor;