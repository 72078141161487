export let fonts = [
    {
        label: "Default",
        link: "",
        family: "",
        value: "default",
        paramValue: 'a'
    },
    {
        label: "Open Sans",
        link: "https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;800&display=swap",
        family: "'Open Sans', sans-serif",
        value: "open-sans",
        paramValue: 'b'
    },
    {
        label: "Roboto",
        link: "https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;700&display=swap",
        family: "'Roboto', sans-serif",
        value: "roboto",
        paramValue: 'c'
    },
    {
        label: "Bangers",
        link: "https://fonts.googleapis.com/css2?family=Bangers&display=swap",
        family: "'Bangers', cursive",
        value: "bangers",
        paramValue: 'd'
    },
    {
        label: "Comfortaa",
        link: "https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;700&display=swap",
        family: "'Comfortaa', cursive",
        value: "comfortaa",
        paramValue: 'e'
    },
    {
        label: "Creepster",
        link: "https://fonts.googleapis.com/css2?family=Creepster&display=swap",
        family: "'Creepster', cursive",
        value: "creepster",
        paramValue: 'f'
    },
    {
        label: "Dancing Script",
        link: "https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;700&display=swap",
        family: "'Dancing Script', cursive",
        value: "dancing-script",
        paramValue: 'g'
    },
    {
        label: "DM Serif Display",
        link: "https://fonts.googleapis.com/css2?family=DM+Serif+Display&display=swap",
        family: "'DM Serif Display', serif",
        value: "dm-serif-display",
        paramValue: 'h'
    },
    {
        label: "Eczar",
        link: "https://fonts.googleapis.com/css2?family=Eczar:wght@400;700&display=swap",
        family: "'Eczar', serif",
        value: "eczar",
        paramValue: 'i'
    },
    {
        label: "Heebo",
        link: "https://fonts.googleapis.com/css2?family=Heebo:wght@300;400;700&display=swap",
        family: "'Heebo', sans-serif",
        value: "heebo",
        paramValue: 'j'
    },
    {
        label: "Lato",
        link: "https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap",
        family: "'Lato', sans-serif",
        value: "lato",
        paramValue: 'k'
    },
    {
        label: "Lobster",
        link: "https://fonts.googleapis.com/css2?family=Lobster&display=swap",
        family: "'Lobster', cursive",
        value: "lobster",
        paramValue: 'l'
    },
    {
        label: "Luckiest Guy",
        link: "https://fonts.googleapis.com/css2?family=Luckiest+Guy&display=swap",
        family: "'Luckiest Guy', cursive",
        value: "luckiest-guy",
        paramValue: 'm'
    },
    {
        label: "Pacifico",
        link: "https://fonts.googleapis.com/css2?family=Pacifico&display=swap",
        family: "'Pacifico', cursive",
        value: "pacifico",
        paramValue: 'n'
    },
    {
        label: "Permanent Marker",
        link: "https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap",
        family: "'Permanent Marker', cursive",
        value: "permanent-marker",
        paramValue: 'o'
    },
    {
        label: "Poppins",
        link: "https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700&display=swap",
        family: "'Poppins', sans-serif",
        value: "poppins",
        paramValue: 'p'
    },
    {
        label: "Righteous",
        link: "https://fonts.googleapis.com/css2?family=Righteous&display=swap",
        family: "'Righteous', cursive",
        value: "righteous",
        paramValue: 'q'
    },
    {
        label: "Rubik Wet Paint",
        link: "https://fonts.googleapis.com/css2?family=Rubik+Wet+Paint&display=swap",
        family: "'Rubik Wet Paint', cursive",
        value: "rubik-wet-paint",
        paramValue: 'r'
    },
    {
        label: "Water Brush",
        link: "https://fonts.googleapis.com/css2?family=Water+Brush&display=swap",
        family: "'Water Brush', cursive",
        value: "water-brush",
        paramValue: 's'
    }
]
