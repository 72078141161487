import debounce from "lodash.debounce";
import {fonts} from "../constants/fonts"

let search = window.location.search;
let params = new URLSearchParams(search);

  const changeHandler = (value, name) => {

    if(typeof value === 'string' && value.charAt( 0 ) == '#'){
      value = value.substring(1);
    }
    
    const url = new URL(window.location);
    const map = mappings.find(element => element.name === name);

    if(name === 'selectedFont'){
      const foundFont = mapFont(value.value);
      value = foundFont.paramValue;
    }

    if(value === true){
      value = "t";
    } else if(value === false){
      value = "f";
    }

    if(typeof value === 'object' && value !== null){
      value = JSON.stringify(value);
    }

    url.searchParams.set(map.param, value);
    window.history.pushState({}, '', url);
  }

  export const debounceQueryUpdate = debounce(changeHandler, 100);

  export const getQueryValue = (name, defaultValue) => {
    const map = mappings.find(element => element.name === name);
    name = map.param;

    let param = params.get(name) ? params.get(name) : defaultValue;

    if(map.name === "selectedFont"){
      param = fonts.find(font => font.paramValue === param);
    } else if(param === "t"){
      param = true;
    } else if(param === "f"){
      param = false;
    }

    return param;
  }


  const mappings = [
    {name: "progressColor", param: "a"},
    {name: "fontColor", param: "b"},
    {name: "fontSize", param: "c"},
    {name: "imgVisible", param: "d"},
    {name: "borderVisible", param: "e"},
    {name: "borderColor", param: "f"},
    {name: "backgroundColor", param: "g"},
    {name: "width", param: "h"},
    {name: "height", param: "i"},
    {name: "borderSize", param: "j"},
    {name: "progressPosition", param: "k"},
    {name: "progressBarHeight", param: "l"},
    {name: "selectedFont", param: "m"},
    {name: "titleVisible", param: "n"},
    {name: "stackText", param: "o"},
    {name: "borderRadius", param: "p"},
    {name: "backgroundColor.firstColor", param: "bf"},
    {name: "backgroundColor.secondColor", param: "bs"},
    {name: "backgroundColor.useGradient", param: "bg"},
    {name: "progressColor.firstColor", param: "pf"},
    {name: "progressColor.secondColor", param: "ps"},
    {name: "progressColor.useGradient", param: "pg"},
  ]
    

  const mapFont = (value) => {
    return fonts.find(font => font.value === value);
  }