import React, {useState} from 'react';
import { CopyBlock, dracula } from "react-code-blocks";

function CodeBlock(props) {
  let {styles} = props;
  const [isCopied, setIsCopied] = useState(false);

    return (
      <div className="code-block-wrapper container max-w-screen-xl mx-auto mt-10">
      <div className="code-block">
        <CopyBlock
          text={styles}
          language={"css"}
          theme={dracula}
          showLineNumbers={true}
          startingLineNumber={1}
          codeBlock
        />
        
      </div>
      <div className={"copyButton " + (isCopied ? "copied" : "")} onClick={() => {navigator.clipboard.writeText(styles).then((e)=>{
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 3000);
      })}}>
        <span>
          Copied!
        </span>
        <span>
          Copy to Clipboard
        </span>
      </div>
      </div>
    );
  }
  
  export default CodeBlock;