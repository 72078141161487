import './App.css';
import GoalEditor from './pages/goal-editor/goal-editor';
import Header from "./pages/common/header";
import Footer from './pages/common/footer';

import {
  Routes,
  Route,
  Navigate
} from "react-router-dom";
import { useEffect, useState } from 'react';


function App() {


  return (
      <div className="App">
        <Header />
        <Routes>
          <Route
            path="*"
            element={<Navigate to="goaleditor" replace />}
          />
          <Route path="goaleditor" element={<GoalEditor />} />
        </Routes>
        <Footer />
      </div>
  );
}

export default App;
